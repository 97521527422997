"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lookupSymbols = exports.lookupSymbol = exports.VarScope = exports.VarType = void 0;
/** Type of a variable symbol. */
var VarType;
(function (VarType) {
    /** Regular variable. */
    VarType["VAR"] = "var";
    /** Static variable. */
    VarType["STATIC_VAR"] = "staticVar";
    /** Constant. */
    VarType["CONST"] = "const";
    /** FUNCTION or SUB argument. */
    VarType["ARG"] = "arg";
})(VarType = exports.VarType || (exports.VarType = {}));
/** Scope of a variable symbol. */
var VarScope;
(function (VarScope) {
    VarScope["LOCAL"] = "local";
    VarScope["GLOBAL"] = "global";
})(VarScope = exports.VarScope || (exports.VarScope = {}));
function lookupSymbol(symbolTable, name) {
    var _a;
    return ((_a = symbolTable.find(function (symbol) { return symbol.name.toLowerCase() === name.toLowerCase(); })) !== null && _a !== void 0 ? _a : null);
}
exports.lookupSymbol = lookupSymbol;
function lookupSymbols(symbolTable, name) {
    return symbolTable.filter(function (symbol) { return symbol.name.toLowerCase() === name.toLowerCase(); });
}
exports.lookupSymbols = lookupSymbols;
