"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataItem = exports.dataItemTypeName = void 0;
var types_1 = require("./types");
function dataItemTypeName(_a) {
    var _ = _a[0], value = _a[1];
    return value === null ? 'null' : typeof value;
}
exports.dataItemTypeName = dataItemTypeName;
/** Converts a data item to the expected type. */
function getDataItem(item, expectedTypeSpec) {
    var _ = item[0], value = item[1];
    if ((0, types_1.isNumeric)(expectedTypeSpec)) {
        switch (typeof value) {
            case 'number':
                return value;
            case 'object':
                return 0;
            default:
                throwDataItemTypeError(item, expectedTypeSpec);
        }
    }
    else if ((0, types_1.isString)(expectedTypeSpec)) {
        switch (typeof value) {
            case 'string':
                return value;
            case 'object':
                return '';
            default:
                throwDataItemTypeError(item, expectedTypeSpec);
        }
    }
    else {
        throw new Error("Unknown expected type spec: ".concat(JSON.stringify(expectedTypeSpec)));
    }
}
exports.getDataItem = getDataItem;
function throwDataItemTypeError(item, expectedTypeSpec) {
    var _a = item[0], line = _a[0], col = _a[1], _ = item[1];
    throw new Error("expected ".concat((0, types_1.typeSpecName)(expectedTypeSpec), ", ") +
        "got ".concat(dataItemTypeName(item), " data item ") +
        "(defined at ".concat(line, ":").concat(col, ")"));
}
