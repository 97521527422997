"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** Round a number to integer using BASIC's round-half-to-even rule. */
function roundHalfToEven(n) {
    if (n - Math.floor(n) === 0.5) {
        return Math.round(n / 2) * 2;
    }
    else {
        return Math.round(n);
    }
}
exports.default = roundHalfToEven;
