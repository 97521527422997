"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcDefType = exports.procTypeName = exports.isFnOrDefFn = exports.ProcType = exports.typeSpecName = exports.areMatchingSingularTypes = exports.areMatchingElementaryTypes = exports.isSingularType = exports.isUdt = exports.isElementaryType = exports.isArray = exports.isString = exports.isIntegral = exports.isNumeric = exports.arraySpec = exports.stringSpec = exports.doubleSpec = exports.singleSpec = exports.longSpec = exports.integerSpec = exports.DataType = void 0;
/** Overall type of the value. */
var DataType;
(function (DataType) {
    /** 16-bit integer */
    DataType["INTEGER"] = "integer";
    /** 32-bit integer */
    DataType["LONG"] = "long";
    /** 32-bit floating point. */
    DataType["SINGLE"] = "single";
    /** 64-bit floating point. */
    DataType["DOUBLE"] = "double";
    /** String. */
    DataType["STRING"] = "string";
    /** Array. */
    DataType["ARRAY"] = "array";
    /** User-defined data type, a.k.a. records. */
    DataType["UDT"] = "udt";
})(DataType = exports.DataType || (exports.DataType = {}));
// Helpers for creating DataTypeSpec instances.
function integerSpec() {
    return { type: DataType.INTEGER };
}
exports.integerSpec = integerSpec;
function longSpec() {
    return { type: DataType.LONG };
}
exports.longSpec = longSpec;
function singleSpec() {
    return { type: DataType.SINGLE };
}
exports.singleSpec = singleSpec;
function doubleSpec() {
    return { type: DataType.DOUBLE };
}
exports.doubleSpec = doubleSpec;
function stringSpec() {
    return { type: DataType.STRING };
}
exports.stringSpec = stringSpec;
function arraySpec(elementTypeSpec, dimensionSpecs) {
    return { type: DataType.ARRAY, elementTypeSpec: elementTypeSpec, dimensionSpecs: dimensionSpecs };
}
exports.arraySpec = arraySpec;
// Helpers for type checking.
function isNumeric(t) {
    return [
        DataType.INTEGER,
        DataType.LONG,
        DataType.SINGLE,
        DataType.DOUBLE,
    ].includes(getType(t));
}
exports.isNumeric = isNumeric;
function isIntegral(t) {
    return [DataType.INTEGER, DataType.LONG].includes(getType(t));
}
exports.isIntegral = isIntegral;
function isString(t) {
    return getType(t) === DataType.STRING;
}
exports.isString = isString;
function isArray(t) {
    return getType(t) === DataType.ARRAY;
}
exports.isArray = isArray;
function isElementaryType(t) {
    return isNumeric(t) || isString(t);
}
exports.isElementaryType = isElementaryType;
function isUdt(t) {
    return getType(t) === DataType.UDT;
}
exports.isUdt = isUdt;
function isSingularType(t) {
    return isElementaryType(t) || isUdt(t);
}
exports.isSingularType = isSingularType;
function areMatchingElementaryTypes(typeSpec1, typeSpec2) {
    return ((isNumeric(typeSpec1) && isNumeric(typeSpec2)) ||
        (isString(typeSpec1) && isString(typeSpec2)));
}
exports.areMatchingElementaryTypes = areMatchingElementaryTypes;
function areMatchingSingularTypes(typeSpec1, typeSpec2) {
    return (areMatchingElementaryTypes(typeSpec1, typeSpec2) ||
        (isUdt(typeSpec1) && isUdt(typeSpec2) && typeSpec1.name === typeSpec2.name));
}
exports.areMatchingSingularTypes = areMatchingSingularTypes;
function typeSpecName(t) {
    switch (t.type) {
        case DataType.ARRAY:
            return "".concat(typeSpecName(t.elementTypeSpec), " array");
        case DataType.UDT:
            return "\"".concat(t.name, "\"");
        default:
            return t.type;
    }
}
exports.typeSpecName = typeSpecName;
function getType(t) {
    return typeof t === 'object' ? t.type : t;
}
/** Type of a procedure. */
var ProcType;
(function (ProcType) {
    /** SUB procedure. */
    ProcType["SUB"] = "sub";
    /** FUNCTION procedure. */
    ProcType["FN"] = "fn";
    /** DEF FN procedure. */
    ProcType["DEF_FN"] = "defFn";
})(ProcType = exports.ProcType || (exports.ProcType = {}));
function isFnOrDefFn(procType) {
    return procType === ProcType.FN || procType === ProcType.DEF_FN;
}
exports.isFnOrDefFn = isFnOrDefFn;
/** Returns the user-facing name of a ProcType. */
function procTypeName(procType) {
    var _a;
    var NAMES = (_a = {},
        _a[ProcType.SUB] = 'SUB procedure',
        _a[ProcType.FN] = 'FUNCTION procedure',
        _a[ProcType.DEF_FN] = 'DEF FN procedure',
        _a);
    return NAMES[procType];
}
exports.procTypeName = procTypeName;
/** The type of a procedure definition. */
var ProcDefType;
(function (ProcDefType) {
    /** Built-in function. */
    ProcDefType["BUILTIN"] = "builtin";
    /** User-defined function in the current module. */
    ProcDefType["MODULE"] = "module";
})(ProcDefType = exports.ProcDefType || (exports.ProcDefType = {}));
