"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintArgType = exports.ExecutionDirectiveType = void 0;
var ExecutionDirectiveType;
(function (ExecutionDirectiveType) {
    ExecutionDirectiveType["GOTO"] = "goto";
    ExecutionDirectiveType["GOSUB"] = "gosub";
    ExecutionDirectiveType["RETURN"] = "return";
    ExecutionDirectiveType["END"] = "end";
    ExecutionDirectiveType["EXIT_PROC"] = "exitProc";
})(ExecutionDirectiveType = exports.ExecutionDirectiveType || (exports.ExecutionDirectiveType = {}));
/** Type of an argument to print(). */
var PrintArgType;
(function (PrintArgType) {
    PrintArgType["COMMA"] = "comma";
    PrintArgType["SEMICOLON"] = "semicolon";
    PrintArgType["VALUE"] = "value";
})(PrintArgType = exports.PrintArgType || (exports.PrintArgType = {}));
