"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
/** Returns a VT100 escape sequence prefixed by "ESC [". */
function ESC(c) {
    return "\u001B[".concat(c);
}
/** Returns a DOS keyboard scan code prefixed by NUL. */
function NUL(n) {
    return String.fromCharCode(0, n);
}
/** Map ANSI / VT100 sequences to DOS keyboard scan codes. */
var ANSI_TERMINAL_KEY_CODE_MAP = (_a = {},
    _a[ESC('A')] = NUL(72),
    _a[ESC('B')] = NUL(80),
    _a[ESC('C')] = NUL(77),
    _a[ESC('D')] = NUL(75),
    _a);
exports.default = ANSI_TERMINAL_KEY_CODE_MAP;
